import {
  WppActionButton,
  WppIconDraft,
  WppIconMailAdd,
  WppIconNewspaper,
  WppIconApp,
  WppIconPeople,
  WppIconImage,
  WppSkeleton,
  WppTypography,
  WppIconUserAdd,
  WppIconGrid,
  WppIconPitch,
  WppIconGraph,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import React, { Fragment } from 'react'

import { useFetchApps } from 'api/assistant/queries/apps/useFetchApps'
import { AppAPI } from 'types/app/app'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import styles from './AppsList.module.scss'

type IconKey =
  | 'CS Copywriter' // copywriter
  | 'CS Email Writer' // writeEmail
  | 'CS Headline Generator' // generateHeadlines
  | 'Foundational' // foundational
  | 'Build Personas' // buildPersonas
  | 'Focus Group' // focusGroup
  | 'Content Matrix' // contentMatrix
  | 'RFI' // RFI Helper Tool
  | 'Q&A Visualisation'

const knownIcons = [
  'CS Copywriter',
  'CS Email Writer',
  'CS Headline Generator',
  'Foundational',
  'Build Personas',
  'Focus Group',
  'Content Matrix',
  'RFI',
  'Q&A Visualisation',
]

const HardcodedIcons: { [key in IconKey]: React.ReactElement } = {
  'CS Copywriter': <WppIconDraft slot="icon-start" />,
  'CS Email Writer': <WppIconMailAdd slot="icon-start" />,
  'CS Headline Generator': <WppIconNewspaper slot="icon-start" />,
  Foundational: <WppIconImage slot="icon-start" />,
  'Build Personas': <WppIconUserAdd slot="icon-start" />,
  'Focus Group': <WppIconPeople slot="icon-start" />,
  'Content Matrix': <WppIconGrid slot="icon-start" />,
  RFI: <WppIconPitch slot="icon-start" />,
  'Q&A Visualisation': <WppIconGraph slot="icon-start" />,
}

export default function AppsList({
  assistantWidth = 440,
  isAiAssistantOpen,
  onMouseEnter,
  isSidebarExpanded,
}: {
  isExpanded?: boolean
  assistantWidth?: number
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  onMouseEnter?: () => void
  isSidebarExpanded?: boolean
}) {
  const { osApi, osContext } = useOs()
  const { data: appsAPI, isLoading: isLoadingApps } = useFetchApps({
    params: { itemsPerPage: 50 },
  })

  const openApp = (app: AppAPI) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: isAiAssistantOpen ? EVENTS.ACTIONS.APP_LAUNCH : EVENTS.ACTIONS.APP_LAUNCH,
        params: [
          { key: 'productName', value: app.name },
          { key: 'launchedFrom', value: isAiAssistantOpen ? 'AItoolbarMainMenu' : 'AItoolbarMiniMenu' },
          { key: 'productType', value: 'iframe' },
        ],
      },
    })

    osApi.navigation.openCompactApp({
      name: app.name,
      url: app.appUrl,
      overlayProps: {
        bounds: 'parent',
        enableUserSelectHack: false,
        default: {
          x: window.innerWidth - (!isAiAssistantOpen ? 44 : assistantWidth) - 8 - (app.width || 0) - 8,
          y: 86,
          width: app.width,
          height: window.innerHeight - 63 - 48,
        },
        style: {
          zIndex: 50,
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })

    // const modal = showOverlay(
    //   'iframe',
    //   <div className={styles.iframeContainer}>
    //     <iframe className={styles.iframe} title={app.name} src={app.appUrl} />
    //   </div>,
    //   {
    //     defaultWidth: app.width,
    //     defaultPosition: {
    //       x: window.innerWidth - (!isAiAssistantOpen ? 44 : assistantWidth) - 8 - (app.width || 0) - 8,
    //       y: 24,
    //     },
    //     resizable: app.resizable,
    //     name: app.name,
    //     icon: knownIcons.includes(app.name) ? HardcodedIcons[app.name as IconKey] : <WppIconApp slot="icon-start" />,
    //   },
    //   () => {
    //     modal.close()
    //   },
    // )
  }

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter()
  }

  return (
    <>
      {isLoadingApps &&
        Array.from({ length: 5 }).map((_, index) => (
          <div key={index}>
            <WppSkeleton width={24} height={24} animation variant="rectangle" />
          </div>
        ))}
      {!isLoadingApps && (
        <Fragment>
          {(isAiAssistantOpen
            ? appsAPI.filter(a => a.tenantId === osContext.tenant.id || !a.tenantId)
            : appsAPI.filter(a => a.tenantId === osContext.tenant.id || !a.tenantId).slice(0, 5)
          ).map(app => {
            const Icon = HardcodedIcons[app.name as IconKey]
            return (
              <Fragment key={app.id}>
                <WppActionButton
                  className={clsx({ [styles.button]: isSidebarExpanded })}
                  variant="secondary"
                  onClick={() => openApp(app)}
                  onMouseEnter={handleMouseEnter}
                >
                  {!app.iconUrl && knownIcons.includes(app.name as any) ? Icon : <WppIconApp slot="icon-start" />}
                  {isSidebarExpanded ? (
                    <WppTypography className={styles.appName} tag="span" type="s-body">
                      {app.name}
                    </WppTypography>
                  ) : (
                    ''
                  )}
                </WppActionButton>
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </>
  )
}
